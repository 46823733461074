<template>
    <div class="row">
        <div class="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 class="h5 mb-0  font-weight-bold">Projects</h1>
            <router-link to="/projects" class="mg-btn-gold" exact>
                View All Projects 
            </router-link>  
        </div>
    </div>
    <div class="row setforprojects_x shimmer-wrapper" v-if="tableLoader == 1">
        <div class="col-sm-4 mb-4" v-for="index in 6" :key="index">
            <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                    <div class="align-items-center w-100">                       
                        <div class="cos text-left">
                            <div class="h6 text-xs font-weight-bold text-primary text-uppercase mb-1"> 
                            </div>
                            <div class="datedash0"></div>                                
                            <div class="setusers_vl w-100 d-flex justify-content-between align-items-center mt-3">
                                <div class="datedash0users">
                                </div>
                                View Details
                            </div>
                        </div>                            
                    </div>
                </div>
            </div>
        </div>   
    </div>
    <div class="row setforprojects_x" v-else>
        <div class="col-sm-4 mb-4" v-for="(item) in $store.state.projectList" :key="item._id">
            <div class="card border-left-primary shadow h-100 py-2">
                <div class="card-body">
                    <div class="align-items-center w-100">                       
                        <div class="cos text-left">
                            <div class="h6 text-xs font-weight-bold text-primary text-uppercase mb-1">                                
                                {{ item.title }}
                            </div>
                            <div class="datedash0">{{ item.start_date }}</div>                                
                            <div class="setusers_vl w-100 d-flex justify-content-between align-items-center mt-3">
                                <div class="datedash0users">
                                    <div v-html="imgDesign(item.users)" />
                                </div>
                                <router-link :to="`/project/${item._id}/board`" class="mg-btn-goldx nwclasxss px-2" exact>
                                    View Details
                                </router-link>
                            </div>
                        </div>                            
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'HomeProjectPage',
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            totalProjects: 0,
            projectList: [],
            tableLoader: 1,
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.loadProjectData();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.loadProjectData();
            }
            return newValue
        },
    },
    methods: {
        imgDesign(users) {
            var len = users.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                if(len <= 3 || i < 2){
                    htmls += '<div class="mg-thumbnail">';
                        if(users[i].initials == null){
                            htmls += '<img src="'+this.img_url + '' + users[i].user_image+'" alt="'+users[i].name+'" title="'+users[i].name+'" />';
                        }else{
                            htmls += '<span title="'+users[i].name+'">'+users[i].initials+'</span>';
                        }
                    htmls += '</div>';
                }                        
            }
            if(len > 2 && len > 3){
                htmls += '<div class="mg-thumbnail">';
                    var cntVal = len-2;
                    htmls += '+'+cntVal;
                htmls += '</div>';
            }
            return htmls;
        },
        async loadProjectData() {
            this.tableLoader = 1
            let token = localStorage.getItem('mgTasktoken');
            if (!token) {
                this.$store.state.user.accessToken = ''
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'dashboard/latest-projects')
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.projectList = []
                        this.projectList = resp.data.data
                        this.$store.state.projectList = this.projectList
                        
                        if (this.projectList.length > 0) {
                            this.tableLoader = 3
                        } else {
                            this.tableLoader = 2
                        }
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
    },
}
</script>