<template>    
    <div class="dahboard_view_dash_home">
        <div class="top_row_box_dash d-flex align-items-center justify-content-between">
            <div class="top_row_box_dash_left">
                <h1>Hello, {{ $store.state.user.userInfo.name }}!</h1>
                <p>Quickly access your recent boards, Inbox and workspaces</p>
            </div>
            
            <div class="top_row_box_dash_right d-flex align-items-center">
                <div class="header-background-container">
                    <HeaderBackgroundSvgIconPage />
                </div>
                <router-link to="/tasks" class="mg-btn-gold" exact>
                    Quick Add Task 
                </router-link>   
            </div>
        </div>
        <div class="box_layout_dash_maincxv">
            <div class="container-fluid">
                <div class="box_layout_dash">
                    <div class="row my-5">
                        <div class="col-sm-2">
                            <div class="card ts1_color shadow h-100 p-3">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#3dd9eb" d="M11,18c-3.313,0-6,2.686-6,6c0,3.313,2.687,6,6,6c3.314,0,6-2.687,6-6C17,20.687,14.314,18,11,18"/><path fill="#3dd9eb" d="M37,5c-3.313,0-6,2.687-6,6c0,3.313,2.687,6,6,6c3.314,0,6-2.687,6-6C43,7.687,40.314,5,37,5"/><path fill="#3dd9eb" d="M37,31c-3.313,0-6,2.686-6,6c0,3.313,2.687,6,6,6c3.314,0,6-2.687,6-6C43,33.687,40.314,31,37,31"/><path fill="#6c19ff" d="M31.819,14.028L25.073,22h-8.415C16.88,22.626,17,23.299,17,24c0,0.701-0.12,1.374-0.341,2h8.414 l6.746,7.973c0.688-1.175,1.765-2.095,3.053-2.584L28.62,24l6.251-7.389C33.583,16.123,32.507,15.202,31.819,14.028"/><path fill="#00b3d7" d="M16.658,22H11v4h5.659C16.88,25.375,17,24.701,17,24C17,23.299,16.88,22.626,16.658,22"/><path fill="#00b3d7" d="M35.474,9.708l-3.655,4.32c0.688,1.175,1.764,2.095,3.053,2.584l3.655-4.319L35.474,9.708"/><path fill="#00b3d7" d="M34.872,31.389c-1.288,0.489-2.365,1.409-3.053,2.584l3.655,4.319l3.053-2.584L34.872,31.389"/></svg>
                                        </div>
                                        <h3 class="mb-2">Total Projects</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalProjects}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="card ts2_color border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">                                       
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#ddbaff" d="M24,44L24,44c-8.216,0-15.137-6.14-16.116-14.297l-0.797-4.639C5.871,14.924,13.788,6,24,6h0 c10.212,0,18.129,8.924,16.912,19.063l-0.797,4.639C39.137,37.86,32.216,44,24,44z"/><path fill="#6c19ff" d="M37.701,10.916c-0.825-1.117-1.787-2.133-2.858-3.017C31.912,5.474,28.145,4.003,24,4.003 c-4.145,0-7.912,1.471-10.844,3.895c-0.554,0.458-1.084,0.951-1.58,1.485c-3.115,3.323-4.903,7.879-4.573,12.777 c3.362-1.449,5.88-4.482,6.615-8.158h20.764c0.735,3.677,3.253,6.709,6.615,8.158C41.278,17.982,40.019,14.053,37.701,10.916z"/><path fill="#ddbaff" d="M40,31H8c-1.657,0-3-1.343-3-3s1.343-3,3-3h32c1.657,0,3,1.343,3,3S41.657,31,40,31z"/><path fill="#2100c4" d="M37.701,13.913c-0.825-1.117-1.787-2.133-2.858-3.017C31.912,8.471,28.145,7,24,7 c-4.145,0-7.912,1.471-10.844,3.895c-0.554,0.458-1.084,0.951-1.58,1.485c-3.115,3.323-4.903,7.879-4.573,12.777 c3.362-1.449,5.88-4.482,6.615-8.158h20.764c0.735,3.677,3.253,6.709,6.615,8.158C41.278,20.979,40.019,17.05,37.701,13.913z"/></svg>
                                        </div>
                                        <h3 class="mb-2">Total Members</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalMembers}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="card ts3_color border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">                          
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f5bc00" d="M44,41H4V10h40V41z"/><polygon fill="#eb7900" points="44,26 24,26 4,26 4,10 44,10"/><path fill="#eb7900" d="M17,26h-6v3h6V26z"/><path fill="#eb7900" d="M37,26h-6v3h6V26z"/><rect width="14" height="3" x="17" y="7" fill="#f5bc00"/><path fill="#eb0000" d="M17,23h-6v3h6V23z"/><path fill="#eb0000" d="M37,23h-6v3h6V23z"/></svg>
                                        </div>
                                        <h3 class="mb-2">Total To Do</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{To_Do}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="card ts4_color border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">                                            
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#3dd9eb" d="M43,36H13V11h22c4.418,0,8,3.582,8,8V36z"/><path fill="#7debf5" d="M21,36H5V19c0-4.418,3.582-8,8-8l0,0c4.418,0,8,3.582,8,8V36z"/><path fill="#6c19ff" d="M21,36h5v8h-5V36z"/><polygon fill="#eb0000" points="27,16 27,20 35,20 35,24 39,24 39,16"/><rect width="8" height="3" x="9" y="20" fill="#3dd9eb"/></svg>
                                        </div>
                                        <h3 class="mb-2">Total In Progress</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{In_progress}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="card ts5_color border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.0009 43.6014C34.8258 43.6014 43.601 34.8261 43.601 24.0013C43.601 13.1765 34.8258 4.40125 24.0009 4.40125C13.1761 4.40125 4.40088 13.1765 4.40088 24.0013C4.40088 34.8261 13.1761 43.6014 24.0009 43.6014Z" fill="#F5BC00" fill-opacity="0.22"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 3C12.4094 3 3 12.4094 3 24C3 35.5906 12.4094 45 24 45C35.5906 45 45 35.5906 45 24C45 12.4094 35.5906 3 24 3ZM24 5.8C34.045 5.8 42.2 13.955 42.2 24C42.2 34.045 34.045 42.2 24 42.2C13.955 42.2 5.8 34.045 5.8 24C5.8 13.955 13.955 5.8 24 5.8Z" fill="#F5BC00"/>
                                            <path d="M23.3861 17.5526C26.6077 17.5526 29.2194 20.1643 29.2194 23.3859M29.988 29.9833L35.0527 35.0526M32.7194 23.3859C32.7194 28.5406 28.5408 32.7193 23.3861 32.7193C18.2314 32.7193 14.0527 28.5406 14.0527 23.3859C14.0527 18.2313 18.2314 14.0526 23.3861 14.0526C28.5408 14.0526 32.7194 18.2313 32.7194 23.3859Z" stroke="#00B569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <h3 class="mb-2">Total Review</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{Review}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="card ts6_color border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="colx justify-content-center d-flex flex-column align-items-center w-100">
                                        <div class="icon mb-2">                      
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><polygon fill="#f55376" points="37,6 11,6 11,30 24,36 37,42"/><polygon fill="#f55376" points="11,6 37,6 37,30 24,36 11,42"/><polygon fill="#eb0000" points="11,6 11,30 24,36 31.5,32.538 37,25 37,6"/><g><polygon fill="#fadb00" points="37,29.768 48,21 26,21"/><polygon fill="#fadb00" points="37,29.732 43.957,34 44,34 37,14 30,34 30.044,34"/><polygon fill="#f5bc00" points="39.45,21 34.55,21 32.685,26.329 36.974,29.748 37,29.732 37.026,29.748 41.315,26.329"/></g></svg>
                                        </div>
                                        <h3 class="mb-2">Total Done</h3>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{Done}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <Projects />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import $ from "jquery";
import Projects from '../components/Home/Project.vue'
import HeaderBackgroundSvgIconPage from '../components/Widgets/Icon/HeaderBackgroundSvgIcon.vue'
export default {
    name: 'HomePage',
    components: {
        Projects,
        HeaderBackgroundSvgIconPage,
    },
    async mounted() { 
        this.$store.state.pageTitle = ''
        this.$store.state.isLogin = 1    
        this.$store.state.NotifiCheckVal = Math.ceil(Math.random()*1000000)    
        await this.getCounts();
    },
    data() {
        return {
            totalProjects: 0,
            totalMembers: 0,
            To_Do: 0,
            In_progress: 0,
            Review: 0,
            Done: 0,
        }
    },
    methods: {
        async getCounts() {
            // $('.loadimg').show();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'dashboard/get-counts')
            // await axios.get(process.env.VUE_APP_API_URL + 'dashboard/get-notifications')
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.totalProjects= resp.data.data.data.totalProjects,
                    this.totalMembers= resp.data.data.data.totalMembers,
                    this.To_Do= resp.data.data.data.To_Do,
                    this.In_progress= resp.data.data.data.In_progress,
                    this.Review= resp.data.data.data.Review
                    this.Done= resp.data.data.data.Done
                    $('.loadimg').hide();
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
    }
}
</script>