<template>
    <div class="main_rightcontent mainbody_cssclass" id="wrapper">
        <div class="loadimg" style="display: none;">
            <span class="loader"></span>
        </div>
        <div class="sectionbody">
            <SideMenu></SideMenu>
            <div class="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Header></Header>
                    <div class="container-fluid p-0">
                        <router-view :key="$route.fullPath" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from './Header.vue'
import SideMenu from './SideMenu.vue'
import axios from 'axios'
// import $ from "jquery";
export default {
    name: 'AdminPage',
    components: {
        Header,
        SideMenu
    },
    computed: {
        iRt() {
            return this.$store.state.isRefreshToken
        },
    },
    watch: {
        async iRt(newValue) {
            await this.refreshToken()
            return newValue
        },
    },
    async mounted() {
        if (this.$store.state.attemptLogin == 2) {
            this.$toast.success('Login successfully');
            this.$store.state.attemptLogin = 1;
        }
        this.refreshToken()
    },
    methods: {
        async refreshToken() {
            if (this.$store.state.user.accessToken == '') {
                let token = localStorage.getItem('mgTasktoken');
                if (token) {
                    this.$store.state.user.accessToken = token
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                    await axios.get(process.env.VUE_APP_API_URL + 'authenticate/user-details')
                    .then((resp) => {
                        if (typeof resp.data.data == 'undefined'){
                            this.$store.state.user.accessToken = ''
                            this.$store.state.isActiveToken = ''
                            this.$store.state.user.userInfo = ''
                            localStorage.removeItem("userInfo");
                            localStorage.removeItem("token");
                            this.$toast.success('Your login session has expired for security reasons. Please log in again to continue.')
                            this.$router.push({
                                name: 'Login'
                            })
                        }
                        else if (resp.status == 200 && resp.data.status == 'success' && resp.data.data != '')                      {             
                            this.$store.state.user.userInfo = resp.data.data
                            this.$store.state.user.roleId = resp.data.data.role_id
                            this.$store.state.user.role_name = resp.data.data.role_name
                            this.$store.state.isActiveToken = 1
                            this.$store.state.isRefreshToken = 1
                            // if (this.$route.name === 'Company') {
                            //     this.$router.push({ name: 'Home' })
                            // }
                        }
                        else if (resp.status == 200 && resp.data.status == 'failure') {
                            // this.errorMessage = resp.data.data;
                            // this.errorRes = resp.data.message;
                            this.$store.state.user.accessToken = ''
                            this.$store.state.isActiveToken = ''
                            this.$store.state.user.userInfo = ''
                            localStorage.removeItem("userInfo");
                            localStorage.removeItem("token");
                            this.$toast.success('Your login session has expired for security reasons. Please log in again to continue.')
                            this.$router.push({
                                name: 'Login'
                            })
                        } else {
                            this.$toast.success('Your login session has expired for security reasons. Please log in again to continue.')
                            this.$router.push({
                                name: 'Login'
                            })
                        }
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        this.$store.state.user.accessToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("token");    
                        this.$router.push({
                            name: 'Login'
                        })
                    })
                }
            }
        }
    },

}
</script>
<style scoped>
    .loadimg {
        position: fixed;
        left: 0;
        right: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.1);
        z-index: 99999999;
    }
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: #C99300;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
</style>