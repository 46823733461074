export const taskModule = {
    namespaced: true,
    state: {
        kanbanToDoData: {},
        kanbanToDoLoader: 1,
        kanbanToDoDataLength: 0,
        kanbanInProgressLoader: 1,
        kanbanInProgressDataLength: 0,
        kanbanReviewLoader: 1,
        kanbanReviewDataLength: 0,
        kanbanDoneLoader: 1,
        kanbanDoneDataLength: 0,

        taskList: [],
        totalRecord: 0,
        totalLenght: 0,
        taskListHeight: 0,
        tableLoader: 1,
        
        taskArchiveList: [],
        totalArchiveLenght: 0,
        ArchivetotalRecord: 0,
        taskArchiveListHeight: 0,
        pageTblHeightCls: 0,
        ArchivetableLoader: 1,
    }
}