<template>
     <div class="card border-0">
        <div class="sectionclsbodymain tsk_body_right_back">
            <div class="serach_controls_main tsk_controls_main_cls">
                <div class="serach_controls_main_row1">
                    <div class="tsk_top_filter_left">
                        <div class="serach_controls me-2">
                            <SearchSvgIcon />
                            <input type="search" class="formmginput-control tsk_search" id="searchTxtId" placeholder="Search..." v-on:keyup="reloadSearch()">
                        </div>
                        <div class="serach_controlsx w-200 me-2 projectStatusFilterCls">                    
                            <Select2 v-model="myValue" 
                                v-bind:id="'projectStatusFilterId'"
                                :select=2 
                                :options="$store.state.stausOptions" 
                                :placeholder="'Select Status'"
                                :settings="{ minimumResultsForSearch: -1 }"
                                @change="myChangeEvent($event)" 
                                @select="mySelectEvent($event)"
                            />
                        </div>
                    </div>
                    <div class="tsk_top_filter_right" v-if="$store.state.user.userInfo.role_name === 'Admin'">           
                        <AddEditProject @reload-table-data="reloadSearch" ref="childComponentProjectRef"></AddEditProject> 
                    </div>
                </div>                
            </div>
        </div>
        <div class="card-body p-0">

            <div class="table-responsive pageTblHeightCls">
                <table class="table table-bordered projects_list_tsk" id="dataTable" width="100%" cellspacing="0">
                    <!-- ---admin thead for data main--- -->
                    <thead v-if="$store.state.user.userInfo.role_name === 'Admin'" class="adminside">
                        <tr>
                            <th>Ticket</th>
                            <th>Title</th>
                            <th>Teams</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <!-- ---user thead for data main--- -->
                    <thead v-else class="userside">
                        <tr>
                            <th>Ticket</th>
                            <th>Title</th>
                            <th>Teams</th>
                        </tr>
                    </thead>
                    <!-- ---admin tbody for shimmar data--- -->
                    <tbody class="tbodyCls shimmer-wrapper" v-if="$store.state.project.tableLoader == 1">
                        <tr class="text-center " v-for="index in 13" :key="index">
                            <td v-for="index1 in tblSimCnt" :key="index1">
                                <span class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></span>
                            </td>
                        </tr>
                    </tbody>
                    <!-- <tbody class="tbodyCls" v-else-if="$store.state.project.tableLoader == 1 && $store.state.user.userInfo.role_name === 'Users'">
                        <tr class="text-center " v-for="index in 13" :key="index">
                            <td v-for="index1 in 6" :key="index1">
                                <span class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></span>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody class="tbodyCls adminside" v-else-if="$store.state.project.tableLoader == 2" >
                        <ComponentNoData />
                        <!-- <tr class="text-center">
                            <td v-bind:colspan="tblSimCnt">
                            </td>
                        </tr> -->
                    </tbody>
                    <!-- <tbody class="tbodyCls userside" v-else-if="$store.state.project.tableLoader == 2 && $store.state.user.userInfo.role_name === 'Users'">
                        <tr class="text-center">
                            <td colspan="6">
                                <ComponentNoData />
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody class="tbodyCls" v-bind:class="tblTrCls" v-else >
                        <tr v-for="(item) in $store.state.project.projectList" :key="item._id">
                            <td>#{{ item.project_no }}</td>
                            <td>
                                <!-- <router-link :to="`/project/${item._id}/overview`" class="" exact> -->
                                <router-link :to="`/project/${item._id}/task-list`" class="" exact>
                                    {{ item.title }}
                                </router-link>
                            </td>
                            <td>
                                <div class="d-flex align-items-center" v-html="imgDesign(item.users)" /> 
                            </td>
                            <td v-if="item.status === true">
                                <div class="form-check form-switch mr-1" v-if="$store.state.user.userInfo.role_name === 'Admin'">
                                    <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" checked="" v-on:click="projectStatusModal(item._id, item.status)">
                                    <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                </div>
                            </td>
                            <td v-else>
                                <div class="form-check form-switch mr-1">
                                    <input class="form-check-input" type="checkbox" role="switch" v-bind:id="buttonId(item._id)" v-bind:dataId="item._id" v-bind:dataVal="item.status" v-on:click="projectStatusModal(item._id, item.status)">
                                    <label class="form-check-label" v-bind:for="buttonId(item._id)" ></label>
                                </div>
                            </td>
                            <td v-if="$store.state.user.userInfo.role_name === 'Admin'">
                                <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="modalEditOpen(item._id)" title="Edit">
                                    <EditSvgIcon />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex px-3 databottom justify-content-between align-content-center" v-if="$store.state.project.tableLoader == 3">
                <p v-if="page * pageLength > this.$store.state.project.totalRecord">Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{
                    formatMoney(this.$store.state.project.totalRecord) }} of {{ formatMoney(this.$store.state.project.totalRecord) }} entries</p>
                <p v-else>Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{ formatMoney(page * pageLength) }} of {{
                    formatMoney(this.$store.state.project.totalRecord) }} entries</p>
                <vs-pagination :total="this.$store.state.project.totalLenght" :max="8" v-model="page" @click="reloadPagination(page)"></vs-pagination>
            </div>
        </div>
        <input type="hidden" class="projectValId" id="projectValId"/>
        <input type="hidden" class="projectStatusId" id="projectStatusId"/>
    </div>
</template>
<script>
import AddEditProject from './AddEditProject.vue'
import axios from 'axios'
import $ from "jquery";
import Select2 from 'vue3-select2-component';
import EditSvgIcon from '../Widgets/Icon/EditSvgIcon.vue'
import SearchSvgIcon from '../Widgets/Icon/SearchSvgIcon.vue'
// import ComponentLoader from '../Widgets/Icon/ComponentLoader.vue'
import ComponentNoData from '../Widgets/Icon/ComponentNoData.vue'
import {
    formatMoney
} from '../../helper'
import {
    ref
} from 'vue';
export default {
    name: 'ProjectsListingPage',
    components: {
        Select2,
        AddEditProject,
        EditSvgIcon,
        SearchSvgIcon,
        ComponentNoData,
        // ComponentLoader
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            currentx: 5,
            tableLoader: 1,
            page: 1,
            pageLength: this.$store.state.showEntries,
            searchKey: '',
            totalRecord: 0,
            totalLenght: 0,
            projectList: [],
            myValue: 'true',            
            tblTrCls: 'adminside',            
            tblSimCnt: 5,   
            taskListHeight: 0,
            taskMainListHeight: 0,         
        }
    },
    async mounted() {
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            $('tbody').height(this.$store.state.cntSubvar);
            $('.pageTblHeightCls').height(this.$store.state.tabHei);
            await this.loadProjectData();
        }
        if (this.$store.state.user.role_name === 'Users') {
            $('.projectStatusFilterCls').hide()
            this.tblTrCls = 'userside'
            this.tblSimCnt = 6
        }
        let temps = this
        $("body").on("click", '#searchTxtId', function () {
            if ($('#searchTxtId').val() != '') {
                $('#searchTxtId').val('');
                this.searchKey = '';
                this.page = 1;
                temps.reloadSearch()
            }
        });
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    computed: {
        iAT() {
            return this.$store.state.user.role_name
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.role_name != '') {
                if (this.$store.state.user.role_name === 'Users') {
                    this.tblTrCls = 'userside'
                    this.tblSimCnt = 6
                }
                await this.loadProjectData();
            }
            return newValue
        },
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        formatMoney,
        checkHeightScroll() {
            $('.table').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.table').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom
            var tabHei = headNavCls + tsk_tabs + tsk_controls_main_cls + databottom
            cntSubvar = this.windowHeight - cntSubvar - 30
            tabHei = this.windowHeight - tabHei - 30
            this.taskMainListHeight = tabHei;
            this.taskMainListHeight = tabHei;
            this.$store.state.cntSubvar = cntSubvar
            this.$store.state.tabHei = tabHei
            $('tbody').height(this.$store.state.cntSubvar);
            $('.pageTblHeightCls').height(this.$store.state.tabHei);
            this.checkHeightScroll();
        },
        async reloadPagination(itemObjKey) {
            if (itemObjKey > 0 && itemObjKey !== -1 && itemObjKey <= this.totalLenght) {
                this.page = itemObjKey;
                this.loadProjectData();
            }
        },
        imgDesign(users) {
            var len = users.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                if(len <= 3 || i < 2){
                    htmls += '<div class="mg-thumbnail">';
                        if(users[i].initials == null){
                            htmls += '<img src="'+this.img_url + '' + users[i].user_image+'" alt="'+users[i].name+'" title="'+users[i].name+'" />';
                        }else{
                            htmls += '<span title="'+users[i].name+'">'+users[i].initials+'</span>';
                        }
                    htmls += '</div>';
                }                        
            }
            if(len > 2 && len > 3){
                htmls += '<div class="mg-thumbnail">';
                    var cntVal = len-2;
                    htmls += '+'+cntVal;
                htmls += '</div>';
            }
            return htmls;
        },
        buttonId(id) {
            return "project" + id;
        },
        async reloadSearch() {
            const searchCls = document.getElementById('searchTxtId').value;
            this.searchKey = searchCls.trim();
            this.page = 1;
            this.loadProjectData();
        },
        myChangeEvent(val) {
            this.myValuePriority = val
            this.page = 1;
            this.loadProjectData();
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text })
            this.page = 1;
            this.loadProjectData();
        },
        async projectStatusModal(_id, status) {
            document.getElementById('projectStatusId').value = status
            document.getElementById('projectValId').value = _id
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Confirm`,
                text: 'Are you sure you want to update status?',
                accept: this.projectStatusChange,
                cancel: this.projectStatusCloseChange
            })
        },
        async projectStatusCloseChange() {
            // const projectStatusId = document.getElementById('projectStatusId').value
            let projectValId = document.getElementById('projectValId').value
            let projectValIdsds = true;
            if (projectValId === 'false') {
                projectValIdsds = false;
            }
            document.getElementById("project" + projectValId).checked = projectValIdsds;

            // this.loadProjectData();
        },
        async projectStatusChange() {
            this.formLoader = 2
            const projectStatusId = document.getElementById('projectStatusId').value
            let projectValId = document.getElementById('projectValId').value
            let projectValIdsds = false;
            if (projectStatusId === 'false') {
                projectValIdsds = true;
            }
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'project/status-update', {
                status: projectValIdsds,
                project_id: projectValId,
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        this.loadProjectData();
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.data;
                        this.errorRes = resp.data.message;
                    }
                    this.formLoader = 1
                    this.finishLoader()
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                })
        },
        async loadProjectData() {
            if (this.$store.state.project.projectList.length > 0) {
                // this.tableLoader = 1
            }else{
                this.$store.state.project.tableLoader = 1
            }
            let token = localStorage.getItem('mgTasktoken');
            if (!token) {
                this.$store.state.user.accessToken = ''
            }
            let projectStatusFilterId = document.getElementById('projectStatusFilterId').value
            let status = projectStatusFilterId;
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'project/list', {
                    params: {
                        page: this.page,
                        pageLength: this.pageLength,
                        searchKey: this.searchKey,
                        status: status,
                    }
                })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.projectList = []
                        this.projectList = resp.data.data.records
                        this.totalRecord = resp.data.data.totalRecord
                        this.totalLenght = this.totalRecord / this.pageLength
                        this.totalLenght = Math.ceil(this.totalLenght);

                        this.$store.state.project.projectList = this.projectList
                        this.$store.state.project.totalRecord = this.totalRecord
                        this.$store.state.project.totalLenght = this.totalLenght
                        if (this.projectList.length > 0) {
                            this.$store.state.project.tableLoader = 3
                        } else {
                            this.$store.state.project.tableLoader = 2
                        }
                        this.finishLoader()
                        setTimeout(async () =>
                            // $('tbody').height(this.taskListHeight),
                            this.onResize(),
                        100);
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
        async modalEditOpen(project_id) {
            $('.projectValId').val(project_id);
            this.callChildProjectMethod();
        },
    },
    setup() {
        const childComponentProjectRef = ref(null);
        const callChildProjectMethod = () => {
            if (childComponentProjectRef.value) {
                childComponentProjectRef.value.modalEditOpen();
            }
        };
        return {
            callChildProjectMethod,
            childComponentProjectRef,
        };
    },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>