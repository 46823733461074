<template>
    <vs-button class="mg-btn-gold" color="primary" text-color="whiteprimary"  v-on:click="modalOpen">Add Team</vs-button>    
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#teamModals" id="addTeamBtn"> </button>             
    <div class="modal fade right" id="teamModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">            
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="teamEditId" id="teamEditId">
                    <h5 class="modal-title">{{ modalType }} Team Member</h5> 
                    <button type="button" class="btn-close" id="closePop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="teamFrm" id="teamFrm">
                    <div class="modal-body setformbackform">
                        <div class="row">
                            <div class="col-sm-12">
                                <span v-if="errorMessage != ''">
                                    <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                                </span>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="teamName" class="form-label">Member Name<span class="requiredCls">*</span></label> 
                                    <input type="text" class="form-control" name="teamName" id="teamName" placeholder="Enter member name"  v-model="v$.form.teamName.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.teamName.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="teamEmail" class="form-label">Member Email<span class="requiredCls">*</span></label> 
                                    <input type="email" class="form-control" name="teamEmail" id="teamEmail" placeholder="Enter member email"  v-model="v$.form.teamEmail.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.teamEmail.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2" v-if="modalType == 'Add'"> 
                                    <label for="teamPassword" class="form-label">Password<span class="requiredCls">*</span></label> 
                                    <input type="password" class="form-control" name="teamPassword" id="teamPassword" placeholder="Enter password"  v-model="v$.form.teamPassword.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.teamPassword.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div> 
                                <div class="formmginput-controlx mb-2" v-else>
                                    <div class="formmginput-controlx mb-2">
                                        <label for="passwordEdit" class="form-label">Password</label>
                                    <input type="hidden" class="form-control" name="teamPassword" id="teamPassword" placeholder="Enter password"  v-model="v$.form.teamPassword.$model"> 
                                        <input type="password" class="form-control" name="passwordEdit" placeholder="Enter password" id="passwordEdit" v-model="v$.form.passwordEdit.$model">
                                        <div class="input-errors" v-for="(error, index) of v$.form.passwordEdit.$errors" :key="index">
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="formmginput-controlx mb-2"> 
                                    <div class="formmginput-controlx mb-2">
                                        <label for="roleDropId" class="form-label">Role<span class="requiredCls">*</span></label>
                                        <Select2 v-model="v$.form.role_id.$model" v-bind:id="'roleDropId'" :options="$store.state.role.RoleList" :placeholder="'Select Role'" :settings="{ dropdownParent: '#teamModals' }" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                               <div class="formmginput-controlx mb-2">
                                    <label for="user_image" class="form-label">User Image</label>
                                    <div class="formmginput-controlx_inner fileintset setfileupload d-flex justify-content-between">
                                        <input type="file" class="form-control" name="user_image" id="user_image" accept="image/png, image/gif, image/jpeg" @change="readURL()">
                                        <div class="setupload">
                                            <img id="blahs" src="../../assets/images/No_image_available.svg.png" alt="logo image" class="img-thumbnail" />
                                            <input type="hidden" value="../assets/No_image_available.svg.png" id="imgValT" />
                                        </div>
                                    </div>                                    
                                </div> 
                                <!-- <div class="input-errors">
                                    <div class="error-msg  nonecls" id="imgErrorCls">Please select image</div>
                                </div> -->
                            </div>                            
                        </div>
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="mg-btn-gold"  :disabled="v$.form.$invalid" v-on:click="addTeam" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="mg-btn-gold"  :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import $ from "jquery";
import Select2 from 'vue3-select2-component';
import noImgAvailable from '../../assets/images/No_image_available.svg.png'
import {
    email,
    required,
    // maxLength,
    minLength
} from '@vuelidate/validators'
export default {
    name: 'AddEditTeamPage',
    components: {
        Select2,
    },
    emits: ["reload-table-data"],
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            logoFile: null,
            form: {
                teamName: '',
                teamEmail: '',
                teamPassword: '',
                role_id: '',
                user_image: '',
                password: '',
                passwordEdit: '',
            },
        }
    },
    methods: {
        async modalOpen() {
            this.errorMessage = ''
            this.form.teamName = '';
            this.form.teamEmail = '';
            this.form.password = '';
            this.form.passwordEdit = '';
            this.form.role_id = '';
            this.isNewImg = 0;
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.logoFile = null,
            this.v$.$reset()
            document.getElementById('teamEditId').value = '';
            document.getElementById('blah').src = noImgAvailable;
            document.getElementById('user_image').value = '';
            document.getElementById('addTeamBtn').click();
            setTimeout(async () =>
                $("#teamName").focus(),
            500);
        },
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        async modalEditOpen() {
            let team_id = $('.teamValId').val();
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.teamName = '';
            this.form.teamEmail = '';
            this.form.passwordEdit = '';
            this.modalType = 'Edit',
            this.buttonName = 'Update',
            document.getElementById('teamName').value = '';
            document.getElementById('teamEmail').value = '';
            this.v$.$reset()
            this.formLoader = 2
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'user/get', {
                user_id: team_id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('teamName').value = resp.data.data.name;
                    document.getElementById('teamEmail').value = resp.data.data.email;
                    document.getElementById('teamPassword').value = resp.data.data.email;
                    document.getElementById('roleDropId').value = resp.data.data.role_id;
                    document.getElementById('teamEditId').value = resp.data.data._id;
                    this.v$.form.teamName.$model = resp.data.data.name;
                    this.v$.form.teamEmail.$model = resp.data.data.email;
                    this.v$.form.teamPassword.$model = resp.data.data.email;
                    this.v$.form.role_id.$model = resp.data.data.role_id;
                    document.getElementById('blah').src = this.img_url + '' + resp.data.data.user_image
                    document.getElementById('addTeamBtn').click();
                    this.v$.$reset()
                    this.finishLoader()
                    $('.loadimg').hide();
                    setTimeout(async () =>
                        $("#teamName").focus(),
                    500);
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        readURL() {
            this.logoFile = document.getElementById('user_image').files[0]
            var selectedFile = document.getElementById('user_image').files[0];
            var img = document.getElementById('blahs')
            var reader = new FileReader();
            reader.onload = function () {
                img.src = this.result
            }
            this.isNewImg = 1;
            // document.getElementById('imgErrorCls').style.display = 'none';

            if(selectedFile){
                reader.readAsDataURL(selectedFile);
            }

        },
        async addTeam() {
            this.formLoader = 2
            let _URL = ''
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            const fd = new FormData;

            var teamName = $('#teamName').val();
            var teamEmail = $('#teamEmail').val();
            var teamPassword = $('#teamPassword').val();
            var roleDropId = $('#roleDropId').val();

            fd.append('name', teamName)
            fd.append('email', teamEmail)
            fd.append('role_id', roleDropId)
            fd.append('user_image', this.logoFile)
            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'user/update'       
                fd.append('password', this.form.passwordEdit)         
                fd.append('user_id', $('#teamEditId').val())         
            } else {
                _URL = process.env.VUE_APP_API_URL + 'user/create'
                fd.append('password', teamPassword)               
            }
            await axios.post(_URL, fd)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePop').click();
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                this.finishLoader()
                    this.formLoader = 1
            })
                .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                teamName: {
                    required,
                    min: minLength(2)
                },
                teamEmail: {
                    email,
                    required,
                },
                teamPassword: {
                    required,
                    min: minLength(6)
                },
                role_id: {
                    required,
                },
                passwordEdit: {
                    min: minLength(6)
                },
            },
        }
    },

}
</script>
<style scoped>
.img-thumbnail{
    width: 100px;
    height: 100px;
}
</style>