<template> 
    <vs-button class="mg-btn-gold d-none" id="addManageLabelsBtn" color="primary" text-color="whiteprimary"  v-on:click="modalOpen"><i class="bi bi-tag"></i> Manage labels
    </vs-button>    
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#labelModals" id="addLabelBtn"> </button>             
    <div class="modal fade right managelabelset" id="labelModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="labelEditId" id="labelEditId">
                    <h5 class="modal-title">Manage labels</h5> 
                    <button type="button" class="btn-close" id="closePriorityPop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="labelFrm setformbackform" id="labelFrm">
                    <div class="modal-body"> 
                        <span v-if="errorMessage != ''">
                            <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                        </span>
                        <div class="form-group text-center">
                            <div class="col-md-12">
                                <div class="color-palet">
                                    <div v-html="labelColorHtml(colorList)" /> 
                                </div>
                            </div>
                        </div>
                        <div class="formmginput-controlx mb-2"> 
                            <label for="labelName" class="form-label">Name<span class="requiredCls">*</span></label> 
                            <input type="text" class="form-control" name="labelName" id="labelName" placeholder="enter label name" v-model="v$.form.labelName.$model"> 
                            <div class="input-errors" v-for="(error, index) of v$.form.labelName.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div> 
                        <div class="formmginput-controlx mb-2"> 
                            <input type="hidden" class="form-control" name="labelColor" id="labelColor" v-model="v$.form.labelColor.$model"> 
                            <div class="input-errors" v-for="(error, index) of v$.form.labelColor.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div> 
                        <div class="form-group text-center">
                            <div class="col-md-12">
                                <div class="color-palet">
                                    <div id="label-show-area" class="p15 b-t" v-html="labelHtml(labelDataList)" ></div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="modal-footer"> 
                        <button type="button" class="mg-btn-gold mg-btn-cancel spaceh me-2" id="UpdateDeleteBtn" v-on:click="deleteLabel" v-if="formDeleteLoader === 1">Delete</button>     
                        <button type="button" class="btn btn-danger UpdateDeleteBtn" v-else-if="formDeleteLoader === 2"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</button>

                        <button type="button" class="mg-btn-gold" :disabled="v$.form.$invalid" v-on:click="addLabel" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="mg-btn-gold" :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import {
    required,
    minLength,
} from '@vuelidate/validators'
import $ from "jquery";
export default {
    name: 'LabelAddEditPage',
    emits: ["reload-table-data"],
    data() {
        return {
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            formDeleteLoader: 0,
            colorList: [],
            labelDataList: [],
            form: {
                labelName: '',
                labelColor: '',
            }
        }
    },
    async mounted() {
        let temps = this
        $("body").on("click", '.color-palet span', function () {
            $(".color-palet").find(".active").removeClass("active");
            $(this).addClass("active");
            temps.v$.form.labelColor.$model = $(this).attr("data-color")
            $("#labelColor").val($(this).attr("data-color"));
        });

        temps = this
        $("body").on("click", '.labelEditDelete', function () {
            temps.formDeleteLoader = 1;
            var id = $(this).attr("data-id")
            var color = $(this).attr("data-color")
            var label = $(this).attr("data-label")
            $(".color-palet").find(".active").removeClass("active");
            $('#labelName').val(label);
            temps.modalType = 'Edit';
            temps.buttonName = 'Update',
            document.getElementById('labelEditId').value = id;
            temps.v$.form.labelName.$model = label;
            $("body").find(".color-tag[data-color='"+color+"']").addClass("active");
            $('.UpdateDeleteBtn').show();

        });
    },
    methods: {
        labelColorHtml(colorList) {
            var len = colorList.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {          
                htmls += '<span style="background-color:'+colorList[i]+'" class="color-tag clickable mr15" data-color="'+colorList[i]+'" ></span>';
            }
            return htmls;
        },
        labelHtml(labelDataList) {
            var len = labelDataList.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {          
                htmls += '<span data-act="label-edit-delete" data-id="'+labelDataList[i]._id+'" data-color="'+labelDataList[i].color_code+'" data-label="'+labelDataList[i].label+'" class="badge large mr5 clickable labelEditDelete" style="background-color: '+labelDataList[i].color_code+'">'+labelDataList[i].label+'</span>';
            }
            return htmls;
        },
        async modalOpen() {
            this.errorMessage = ''
            this.formDeleteLoader = 0
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.form.labelName = '';
            this.form.labelColor = '';
            document.getElementById('labelEditId').value = '';
            document.getElementById('labelName').value = '';
            this.v$.$reset()
            await this.labelList();
            $(".color-palet").find(".active").removeClass("active");
            $('.UpdateDeleteBtn').hide();
            document.getElementById('addLabelBtn').click();
            setTimeout(async () =>
                $("#labelName").focus(),
            500);
        },
        async modalAfterOpen() {
            this.errorMessage = ''
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.form.labelName = '';
            this.form.labelColor = '';
            $(".color-palet").find(".active").removeClass("active");
            document.getElementById('labelEditId').value = '';
            document.getElementById('labelName').value = '';
            $('.UpdateDeleteBtn').hide();
            this.v$.$reset()
            await this.labelList();           
        },

        async labelList() {
            $('.loadimg').show();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'label/list')
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.colorList = resp.data.data.color_codes
                        this.labelDataList = resp.data.data.records
                        $('.loadimg').hide();
                        
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    
                    this.formLoader = 1
                })
        },
        async deleteLabel() {
            this.formLoader = 2
            this.formDeleteLoader = 2
            let _URL = ''
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}

            _URL = process.env.VUE_APP_API_URL + 'label/delete'   
            _post = {
                label_id: $('#labelEditId').val(),
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$toast.success(resp.data.message);
                    this.modalAfterOpen()
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
                    this.formDeleteLoader = 0
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
                this.formDeleteLoader = 0
            })   
        },
        async addLabel() {
            this.formLoader = 2
            let _URL = ''
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'label/update'   
                _post = {
                    label_id: $('#labelEditId').val(),
                    label: this.v$.form.labelName.$model,
                    color_code: this.v$.form.labelColor.$model,
                }
            } else {
                _URL = process.env.VUE_APP_API_URL + 'label/create'
                _post = {
                    label: this.v$.form.labelName.$model,
                    color_code: this.v$.form.labelColor.$model,
                }
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePop').click();
                    this.$store.state.label.loadLabelData = 1
                    this.$toast.success(resp.data.message);
                    if (this.modalType == 'Edit') {
                        this.$emit('reload-table-data');
                    }
                    this.modalAfterOpen()
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
                .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                labelName: {
                    required,
                    min: minLength(2)
                },
                labelColor: {
                    required
                },
            },
        }
    },

}
</script>
<style>
    .color-tag {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 2px 10px 0 0;
        transition: all 300ms ease;
        -moz-transition: all 0.1s;
        -webkit-transition: all 0.1s;
        transition: all 0.1s;
    }
    .badge {
        display: inline-block;
        padding: 4px 5px;
        font-weight: normal;
        font-size: 85%;
        margin-top: 5px;
        margin-right: 5px;
        cursor: pointer;
    }
    .color-tag.active {
        border-radius: 50%;
    }
</style>