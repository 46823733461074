<template>
    <div class="task-comment-form-container" v-if="attachmentsData.length > 0">
        <div class="row">
            <!-- <ComponentLoader v-if="attachLoader == 1"/> -->
            <span class="attachMainCls selback_attachment d-flex align-items-start justify-content-start" v-if="attachLoader == 1">
                <div class="descriptionss_cls_tsk mb-1" >
                    <p class="fw-title mb-2">
                        <span class="attachsvgfrorm">
                            <AttachmentSvgIcon />
                        </span>
                        <b>Attachments</b>
                    </p>
                </div>
                <ul class="list-group attachemtn_UI shimmer-wrapper shimmarbackgr">
                    <li class="list-group-item shimmarbackgr"  v-for="index in 2" :key="index">
                        <span class="shimmer-line-w30 shimmer-animate shimmer-line shimmer-line-br"></span>
                        <span>
                            <p class="shimmer-line-full shimmer-animate shimmer-line shimmer-line-br"></p>
                            <div class="add_actionformat mt-2">
                                <i class="hiddetails me-2 shimmer-line-w30 shimmer-animate" ></i>
                                <i class="hiddetails shimmer-line-w30 shimmer-animate" ></i>
                            </div>  
                        </span>                         
                    </li>
                </ul>
                <div class="add_actionformat d-flex justify-content-end">
                    <a href="javascript:;" class="hiddetails showMoreDetCls" >View More</a>
                </div>
            </span>
            <span class="attachMainCls selback_attachment d-flex align-items-start justify-content-start" v-else>
                <div class="descriptionss_cls_tsk mb-1" v-if="attachmentsData.length > 0">
                    <p class="fw-title mb-2">
                        <span class="attachsvgfrorm">
                            <AttachmentSvgIcon />
                        </span>
                        <b>Attachments</b>
                    </p>
                </div>
                <ul class="list-group attachemtn_UI" v-if="attachmentsData.length > 0">
                    <li class="list-group-item" v-bind:class="'img-'+ itemCmt._id" v-for="(itemCmt) in attachmentsData" :key="itemCmt._id">
                        <span :class="itemCmt._id+'imgs'">
                            <!-- <img v-bind:src="itemCmt.filePath" class="img-thumbnail" style="width:70px"  @error="imageLoadError(itemCmt._id)" @click="showSingle(itemCmt)"> -->
                            <img
                                v-if="isImage(itemCmt.filePath)"
                                :src="itemCmt.filePath"
                                class="img-thumbnail"
                                style="width:70px"
                                @error="imageLoadError(itemCmt._id)"
                                @click="showSingle(itemCmt)"
                                />   
                                <svg v-else width="80px" height="80px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H12M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V14.5M9 17H11.5M9 13H15M9 9H10M15.5 18.5H20.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        </span>
                        <span>
                            Added by <b>{{ itemCmt.createdByFullName }}</b> at <b v-tooltip="dateChangeFormat(itemCmt.createdAt)"> 
                                <timeago :datetime="itemCmt.createdAt"/> 
                            </b>
                            <div class="add_actionformat">
                                <a href="javascript:;" class="hiddetails me-2" v-on:click="removeAttachments(itemCmt._id)">
                                    <DeleteSvgIcon />
                                    Delete
                                </a>
                                <a href="javascript:;" class="hiddetails" v-on:click="downloadAttachments(itemCmt.filePath)">
                                    <DownloadSvgIcon />
                                    Download
                                </a>
                            </div>  
                        </span>                         
                    </li>
                </ul>
                <div class="add_actionformat d-flex justify-content-end">
                    <a href="javascript:;" class="hiddetails showMoreDetCls" v-if="totalPage > 1" v-on:click="showMoreAttachments()">View More</a>
                </div>
            </span>
        </div>

        <vue-easy-lightbox
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        ></vue-easy-lightbox>
    </div>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import $ from "jquery";
import axios from 'axios'
//import ComponentLoader from '../../Widgets/Icon/ComponentLoader.vue'
import moment from 'moment'
import AttachmentSvgIcon from "@/components/Widgets/Icon/AttachmentSvgIcon.vue";
import {
    ref
} from 'vue';
import DeleteSvgIcon from '@/components/Widgets/Icon/DeleteSvgIcon.vue';
import DownloadSvgIcon from '@/components/Widgets/Icon/DownloadSvgIcon.vue';
export default {
    name: 'AttachmentTaskPage',
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            attachmentsData: [],
            attachLoader: 1,
            attpage: 1,
            totalPage: 1,
            pageLength: 2,
            totalRecord: 0,
            totCat: 0,
            totalLenght: 0           
        }
    },
    components: {
        //ComponentLoader,
        AttachmentSvgIcon,
        VueEasyLightbox,
        DeleteSvgIcon,
        DownloadSvgIcon,
    },
    async mounted() {
        this.attachmentsData = [];
        this.attpage = 1;
    },
    methods: {
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        dateChangeFormat(dateVal) {
            return moment(dateVal).format('DD-MM-YYYY hh:mm A');
        },
        downloadAttachments(img) {
            axios.get(img, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                console.log(blob)
                const link = document.createElement('a')
                console.log(link)
                link.href = URL.createObjectURL(blob)
                link.download = img
                console.log(img)
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        isImage(filePath) {
            const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i; // Add more extensions if needed
            return imageExtensions.test(filePath);
        },
        imageLoadError(id) {
            // console.log('Image failed to load', id);
            // $('.img-'+ id).hide();
            $('.'+ id+'imgs').hide();
        },
        async showMoreAttachments() {
            this.attpage = this.attpage + 1;
            this.getTaskAttachment(1);
        },
        async removeAttachments(id) {
            this.startLoader()
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task-comment/delete-attachment', {
                attachment_id: id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    $('.img-' + id).hide();
                    let task_id = $('.taskValId').val();
                    const tskAttCnt = $('.atCntVal-' + task_id).val();
                    $('.atCntVal-' + task_id).val(tskAttCnt-1);
                    $('.atCnt-' + task_id).html(tskAttCnt - 1);
                    this.getTaskAttachment(2);
                }
                this.finishLoader()
                
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                // this.errorRes = error.response.data.message;
            })
        },
        async getTaskAttachment(val) {
            if (val == 2) {
                this.attpage = 1
            }
            // $('.loadimg').show();
            if (val == 2) {
                this.attachLoader = 1
            }
            if (this.attpage == 1) {
                this.attachmentsData = [];
            }
            let task_id = $('.taskValId').val();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task/get-task-attachments', {
                task_id: task_id,
                page: this.attpage,
                pageLength: this.pageLength
            })
                .then((resp) => {
                    this.attachLoader = 2
                    if (resp.status == 200 && resp.data.status == 'success') {
                        const attachmentsOpts = resp.data.data.records
                        var len = attachmentsOpts.length;
                        if (this.attpage == 1) {
                            this.attachmentsData = []
                        }
                        for (var i = 0; i < len; i++) {
                            this.attachmentsData.push({
                                filePath: this.img_url+''+attachmentsOpts[i].filePath,
                                createdByFullName: attachmentsOpts[i].createdByFullName,
                                createdAt: attachmentsOpts[i].createdAt,
                                _id: attachmentsOpts[i]._id
                            });
                        }
                        this.totalRecord = resp.data.data.totalRecord
                        this.totalLenght = this.totalRecord / this.pageLength
                        this.totalLenght = Math.ceil(this.totalLenght);
                        this.totalLenght = Math.ceil(this.totalLenght);
                        // const totRec = this.totalRecord / this.totalLenght
                        this.totalPage = 1; 
                        // if (totRec > 1) {
                        //     this.totalPage = 2; 
                        // }
                        this.totCat = this.attpage * this.pageLength
                        if (this.totCat < this.totalRecord) {
                            this.totalPage = 2; 
                            
                        }
                        // $('.loadimg').hide();
                    }
                })
                .catch(error => {
                    // this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
    },
    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        // Img Url , string or Array of string
        // ImgObj { src: '', title: '', alt: '' }
        // 'src' is required
        // allow mixing

        const onShow = () => {
            visibleRef.value = true
        }  
        const showSingle = (itemCmt) => {
            // imgsRef.value = 'http://via.placeholder.com/350x150'
            // imgsRef.value = itemCmt.filePath
            imgsRef.value = [
              { title: itemCmt.createdByFullName, src: itemCmt.filePath },
            ]
            // or
            // imgsRef.value  = {
            //   title: 'itemCmt.createdByFullName',
            //   src: 'http://via.placeholder.com/350x150'
            // }
            onShow()
        }
        const onHide = () => (visibleRef.value = false)

        return {
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            onHide
        };
    },
}
</script>
<style scoped>
    .attachMainCls ul{
        width: 100%;
    }
    .attachMainCls{
        display: flex;
        align-items: center;
        /* width: 100%; */
        flex-direction: column;
    }
    .attachMainCls a{
        margin-top: 10px;
    }
    .img-thumbnail{
        cursor: pointer;
    }
</style>