<template>
    <div class="card overflow-hidden">
        <nav class="tsk_tabs">
            <div class="nav nav-tabs m-0" id="nav-tab" role="tablist">
                <button class="nav-link active tabLinkCls">Overview</button>
                <router-link :to="`/project/${$route.params.projectId}/task-list`" class="nav-link" id="nav-list-tab" >
                    <span class="tabLinkCls">List</span>
                </router-link>
                <router-link :to="`/project/${$route.params.projectId}/board`" class="nav-link" id="nav-karban-tab" >
                    <span class="tabLinkCls">Kanban</span>
                </router-link>
            </div>
        </nav>
        <div class="tab-content p-0" id="nav-tabContent">
            <div class="tab-pane fade active show" id="nav-list" role="tabpanel" aria-labelledby="nav-list-tab">
                <div class="card border-0">
                    <div class="sectionclsbodymain tsk_body_right_back">
                        <!-- <Tasks />  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import axios from 'axios'
export default {
    name: 'ProjectsOverviewPage',
    async mounted() {
        $('.projectBethCls').show();
        this.projectId = this.$route.params.projectId
        $('.projectMenuCls').addClass('router-link-active')
        await this.getProjectDetail();
        this.$store.state.projectIdSelected = ''
    },
    beforeRouteLeave(to, from, next) {
        // console.log(to);
        // console.log(from);
        // console.log(next);
        $('.projectBethCls').hide();
        $('.projectMenuCls').removeClass('router-link-active')
        next()
    },
    methods: {
        async getProjectDetail() {
            $('.loadimg').show();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'project/get', {
                project_id: this.projectId
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$store.state.pageTitle = resp.data.data.title
                        $('.loadimg').hide();
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    // this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.formLoader = 1
                    // this.errorRes = error.response.data.message;
                })
        },
    },

}
</script>