<template>
    <vs-button class="mg-btn-gold d-none " id="addTaskMainBtn" color="primary" text-color="whiteprimary"  v-on:click="modalOpen"><i class="bi bi-plus-circle"></i> Add Task</vs-button>  
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#taskModals" id="addTaskBtn"> </button>  
    <div class="modal fade right" id="taskModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="taskEditId" id="taskEditId">
                    <h5 class="modal-title">{{ modalType }} Task</h5> 
                    <button type="button" class="btn-close" id="closePop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="taskFrm" id="taskFrm">
                    <div class="modal-body"> 
                        <span v-if="errorMessage != ''">
                            <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                        </span>
                        <div class="row">
                            <div class="col-sm-8 setformbackform">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="taskName" class="form-label">Task Name<span class="requiredCls">*</span></label> 
                                    <input type="text" class="form-control" name="taskName" id="taskName" placeholder="Enter task name"  v-model="v$.form.taskName.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.taskName.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div> 
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="projectDescription" class="form-label">Task Description</label> 
                                    <textarea class="form-control" name="projectDescription" id="projectDescription" placeholder="Enter task description"></textarea>
                                </div>
                                <div class="block_tsk_dvl">
                                    <div class="row">
                                        <div class="col-sm-6">
                                                <div class="formmginput-controlx mb-2"> 
                                                    <label for="projectDropId" class="form-label">Project<span class="requiredCls">*</span></label>
                                                    <Select2 v-model="v$.form.project_id.$model" v-bind:id="'projectDropId'" :options="$store.state.project.ProjectList" :placeholder="'Select Project'" :settings="{ dropdownParent: '#taskModals' }" />
                                                </div>
                                            </div>
                                        <div class="col-sm-6">                                           
                                            <div class="formmginput-controlx mb-2">            
                                                <label for="teamDropIds" class="form-label">Assign to</label>
                                                <Multiselect
                                                    v-bind:name="'teamId[]'"
                                                    v-bind:id="'teamFilterId'" 
                                                    v-model="v$.form.user_ids.$model"
                                                    mode="multiple"
                                                    placeholder="Select teams"
                                                    track-by="name"
                                                    label="name"
                                                    :close-on-select="false"
                                                    :search="true"
                                                    :hide-selected="false"
                                                    :options="$store.state.teamMember.TeamMemberImgFilterList"
                                                >
                                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                        <div
                                                            class="multiselect-tag is-user"
                                                            :class="{
                                                                'is-disabled': disabled
                                                            }"
                                                        >
                                                            <div v-if="option.initials == null">  
                                                                <img :src="img_url + '' + option.user_image">
                                                                {{ option.label }}
                                                            </div>
                                                            <div v-else>  
                                                                {{ option.initials }} {{ option.label }}
                                                            </div>
                                                            <span
                                                            v-if="!disabled"
                                                            class="multiselect-tag-remove"
                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                            >
                                                            <span class="multiselect-tag-remove-icon"></span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                            <!-- <div v-html="readMore(element.title, element.task_id)" />  -->
                                                            <div v-if="option.initials == null">  
                                                                <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                                            </div>
                                                            <div v-else>  
                                                                {{ option.initials }} {{ option.label }}
                                                            </div>
                                                    </template>
                                                </Multiselect> 
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 setformbackform">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="projectDropIds" class="form-label">Priority<span class="requiredCls">*</span></label>
                                    <Select2 v-model="v$.form.priority_id.$model" v-bind:id="'projectDropIds'" :options="$store.state.priority.PriorityList" :placeholder="'Select Priority'" :settings="{ dropdownParent: '#taskModals' }" />
                                </div>                               
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="boardDropId" class="form-label">Status (Board)<span class="requiredCls">*</span></label>
                                    <Select2 v-model="v$.form.board_status_id.$model" v-bind:id="'boardDropId'" :options="$store.state.board.BoardList" :placeholder="'Select Board'" :settings="{ dropdownParent: '#taskModals' }" />
                                </div>
                                <div class="formmginput-controlx mb-2">            
                                    <label for="labelsDropIds" class="form-label">Labels</label>
                                    <Multiselect v-model="v$.form.label_ids.$model" mode="multiple" :hide-selected="false" :caret="true" :searchable="true" :create-option="false" :options="$store.state.label.LabelFilterList" placeholder="Select labels" v-bind:name="'labelsId[]'" v-bind:id="'labelsFilterIdT'" />    
                                </div>
                            </div>  
                        </div>                                              
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="mg-btn-gold"  :disabled="v$.form.$invalid" v-on:click="addTasks" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="mg-btn-gold"  :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    minLength,
} from '@vuelidate/validators'
import Select2 from 'vue3-select2-component';
import Multiselect from '@vueform/multiselect'
import $ from "jquery";
import axios from 'axios'
export default {
    name: 'AddEditTaskPage',
    emits: ["reload-table-data"],
    components: {
        Select2,
        Multiselect,
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            form: {
                taskName: '',
                projectDescription: '',
                project_id: '',
                priority_id: '',
                board_status_id: '',
                user_ids: [],
                label_ids: [],
            },
        }
    },
    methods: {
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        async modalOpen() {
            this.errorMessage = ''
            this.form.taskName = '';
            this.form.projectDescription = '';
            this.form.priority_id = '';
            this.form.project_id = '';
            this.form.board_status_id = '';
            this.form.user_ids = [];
            this.form.label_ids = [];
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.v$.$reset()
            document.getElementById('taskName').value = '';
            document.getElementById('projectDescription').value = '';
            document.getElementById('addTaskBtn').click();
            setTimeout(async () =>
                $("#taskName").focus(),
                500);
            if (this.$store.state.projectIdSelected) {
                this.form.project_id = this.$store.state.projectIdSelected
            }
            
        },
        async modalEditOpen() {
            let task_id = $('.taskValId').val();
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.taskName = '';
            this.form.projectDescription = '';
            this.form.priority_id = '';
            this.form.project_id = '';
            this.form.board_status_id = '';
            this.form.user_ids = [];
            this.form.label_ids = [];
            this.modalType = 'Edit';
            this.buttonName = 'Update',
            this.v$.$reset()
            document.getElementById('taskName').value = '';
            document.getElementById('projectDescription').value = '';
            this.formLoader = 2
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task/get', {
                task_id: task_id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('taskName').value = resp.data.data.title;
                    document.getElementById('projectDescription').value = resp.data.data.description;

                    this.v$.form.taskName.$model = resp.data.data.title;
                    this.v$.form.priority_id.$model = resp.data.data.priority_id;
                    this.v$.form.project_id.$model = resp.data.data.project_id;
                    this.v$.form.board_status_id.$model = resp.data.data.board_status_id;
                    this.v$.form.user_ids.$model = resp.data.data.userids;
                    this.v$.form.label_ids.$model = resp.data.data.labelids;

                    document.getElementById('addTaskBtn').click();
                    this.v$.$reset()
                    this.finishLoader()
                    $('.loadimg').hide();
                    setTimeout(async () =>
                        $("#taskName").focus(),
                    500);
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
            document.getElementById('addTaskBtn').click();
            setTimeout(async () =>
                $("#taskName").focus(),
            500);
        },
        async addTasks() {
            this.formLoader = 2
            let _URL = ''
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}

            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'task/update'   
                _post = {
                    task_id: $('.taskValId').val(),
                    title: this.v$.form.taskName.$model,
                    description: $('#projectDescription').val(),
                    priority_id: this.v$.form.priority_id.$model,
                    project_id: this.v$.form.project_id.$model,
                    board_status_id: this.v$.form.board_status_id.$model,
                    user_ids: this.v$.form.user_ids.$model,
                    label_ids: this.v$.form.label_ids.$model,
                }
            } else {
                _URL = process.env.VUE_APP_API_URL + 'task/create'
                _post = {
                    title: this.v$.form.taskName.$model,
                    description: $('#projectDescription').val(),
                    priority_id: this.v$.form.priority_id.$model,
                    project_id: this.v$.form.project_id.$model,
                    board_status_id: this.v$.form.board_status_id.$model,
                    user_ids: this.v$.form.user_ids.$model,
                    label_ids: this.v$.form.label_ids.$model,
                }
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePop').click();
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
                .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                taskName: {
                    required,
                    min: minLength(2)
                },
                projectDescription: {
                    // required,
                },
                project_id: {
                    // required,
                },
                priority_id: {
                    required,
                },
                board_status_id: {
                    required,
                },
                user_ids: {
                    // required
                },
                label_ids: {
                    // required
                },
            },
        }
    },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #35495e;
    margin: 3px 3px 8px;
  }

  .multiselect-tag.is-user img {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
  }

  .multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
  }

  .user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
  }
</style>