<template>
    <vs-button class="mg-btn-gold" color="primary" text-color="whiteprimary"  v-on:click="modalOpen">Add Roles</vs-button>    
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#roleModals" id="addRoleBtn"> </button>             
    <div class="modal fade right" id="roleModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="roleEditId" id="roleEditId">
                    <h5 class="modal-title">{{ modalType }} Role</h5> 
                    <button type="button" class="btn-close" id="closePop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="roleFrm" id="roleFrm">
                    <div class="modal-body"> 
                        <span v-if="errorMessage != ''">
                            <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                        </span>
                        <div class="formmginput-controlx mb-2"> 
                            <label for="roleName" class="form-label">Role Name<span class="requiredCls">*</span></label> 
                            <input type="text" class="form-control" name="roleName" id="roleName" placeholder="enter role name"  v-model="v$.form.roleName.$model"> 
                            <div class="input-errors" v-for="(error, index) of v$.form.roleName.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>  
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="btn btn-primary"  :disabled="v$.form.$invalid" v-on:click="addRole" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="btn btn-primary"  :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import $ from "jquery";
import {
    required,
    minLength
} from '@vuelidate/validators'
export default {
    name: 'RoleAddEditPage',
    data() {
        return {
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            form: {
                roleName: '',
            },
        }
    },
    emits: ["reload-table-data"],
    methods: {
        async modalOpen() {
            this.errorMessage = ''
            this.form.roleName = '';
            this.isNewImg = 0;
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.v$.$reset()
            document.getElementById('roleEditId').value = '';
            document.getElementById('addRoleBtn').click();
            setTimeout(async () =>
                $("#roleName").focus(),
            500);
        },
        async modalEditOpen() {
            let role_id = $('.roleValId').val();
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.roleName = '';
            this.modalType = 'Edit',
            this.buttonName = 'Update',
            document.getElementById('roleName').value = '';
            this.v$.$reset()
            this.formLoader = 2
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'role/get', {
                role_id: role_id
            })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        document.getElementById('roleName').value = resp.data.data.role;
                        document.getElementById('roleEditId').value = resp.data.data._id;
                        this.v$.form.roleName.$model = resp.data.data.role;
                        document.getElementById('addRoleBtn').click();
                        this.v$.$reset()
                        $('.loadimg').hide();
                        setTimeout(async () =>
                            $("#roleName").focus(),
                        500);
                    }
                    this.formLoader = 1
                })
                .catch(error => {
                    // this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.formLoader = 1
                    // this.errorRes = error.response.data.message;
                })
        },
        async addRole() {
            this.formLoader = 2
            let _post = {}
            let _URL = ''
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            var roleName = $('#roleName').val();
            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'role/update'
                var role_id = $('#roleEditId').val();
                _post = {
                    role_id: role_id,
                    role: roleName,
                }
            } else {
                _URL = process.env.VUE_APP_API_URL + 'role/create'
                _post = {
                    role: roleName,
                }
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePop').click();
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
                .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })            
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                roleName: {
                    required,
                    min: minLength(2)
                }
            },
        }
    },

}
</script>
