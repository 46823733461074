<template>
    <div class="container">

          <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <h1>404</h1>
            <h2>The page you are looking for doesn't exist.</h2>
            <router-link to="/" class="btn" >
                Back to home
            </router-link>
            <div class="credits">
              Designed by GoldenTask 
            </div>
          </section>

        </div>
</template>

<script>
export default {
    name: 'PageNotFoundPage',
}
</script>