import { createStore } from "vuex";
import {UserModule} from './module/UserModule'
import {RoleModule} from './module/RoleModule'
import {PriorityModule} from './module/PriorityModule'
import {TeamMemberModule} from './module/TeamMemberModule'
import {BoardModule} from './module/BoardModule'
import {ProjectModule} from './module/ProjectModule'
import {LabelModule} from './module/LabelModule'
import {NotificationModule} from './module/NotificationModule'
import {taskModule} from './module/TaskModule'
import moment from "moment";
export default createStore({
    state: {
        pageTitle: 'Dashboard',
        isRefreshToken: 1,
        NotifiCheckVal: 0,
        isLogin: 1,
        showEntries: 15,
        attemptLogin: 1,
        stausOptions: [{ id: 2, text: 'All' }, { id: true, text: 'Active' }, { id: false, text: 'Inactive' }],
        projectIdSelected: '',
        tbody: '',
        pageTblHeightCls: '',
        notilinkCls: '',
        notificationStartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        notificationEndDate: moment().format("YYYY-MM-DD"),
        arhiveStartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        arhiveEndDate: moment().format("YYYY-MM-DD"),
        projectList: []
    },
    modules: {
        user: UserModule,
        role: RoleModule,
        priority: PriorityModule,
        teamMember: TeamMemberModule,
        board: BoardModule,
        project: ProjectModule,
        label: LabelModule,
        notification: NotificationModule,
        task: taskModule,
    }
})