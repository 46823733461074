import { createRouter, createWebHistory } from "vue-router";
import Login from "../pages/Login.vue"
import Home from "../pages/Home.vue"
import Settings from "../pages/Settings.vue"
import TeamMember from "../pages/TeamMember.vue"
import Projects from "../pages/Projects.vue"
import ProjectsKanban from "../pages/ProjectsKanban.vue"
import ProjectsOverview from "../pages/ProjectsOverview.vue"
import ProjectTaskList from "../pages/ProjectTaskList.vue"
import ProjectsArchive from '@/pages/ProjectsArchive'
import Tasks from "../pages/Tasks.vue"
import TasksKanban from "../pages/TasksKanban.vue"
import TasksArchive from "../pages/TasksArchive.vue"
import Notifications from "../pages/Notifications.vue"
import store from './../vuex/store'

import PageNotFound from "../pages/PageNotFound.vue"
const routes = [
    {
        name: "Login",
        component: Login,
        path: "/login",
    },
    {
        name: "Home",
        component: Home,
        path: "/",
    },
    {
        name: "Settings",
        component: Settings,
        path: "/settings",
    },
    {
        name: "TeamMember",
        component: TeamMember,
        path: "/team-member",
    },
    {
        name: "Projects",
        component: Projects,
        path: "/projects",
    },
    {
        name: "projects_overview",
        component: ProjectsOverview,
        path: "/project/:projectId?/overview",
    },
    {
        name: "projects_tasklist",
        component: ProjectTaskList,
        path: "/project/:projectId?/task-list",
    },
    {
        name: "projects_taskArchive",
        component: ProjectsArchive,
        path: "/project/:projectId?/Archive",
    },
    {
        name: "projects_kanban",
        component: ProjectsKanban,
        path: "/project/:projectId?/board",
    },
    {
        name: "Tasks",
        component: Tasks,
        path: "/tasks",
    },
    {
        name: "Notifications",
        component: Notifications,
        path: "/notifications",
    },
    {
        name: "Tasks_kanban",
        component: TasksKanban,
        path: "/tasks-kanban",
    },
    {
        name: "Tasks_kanban_detail",
        component: TasksKanban,
        path: "/tasks-kanban/:taskId?"
    },
    {
        name: "Tasks_archive",
        component: TasksArchive,
        path: "/tasks-archive",
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound
    },
]

let router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.name == 'Login'){
        if (store.state.user.accessToken == '') {
            let token = localStorage.getItem('token');
            if (token) {
                store.state.user.accessToken = token
                next('/')        
            }
            next()
        } else{
            next('/')        
        }
    } else if (to.name != 'Login') {
        if(store.state.user.accessToken == '') {
            let token = localStorage.getItem('token');
            if (token) {
                store.state.user.accessToken = token
                next()        
            }
            next('/login')        
        } else {
            next()        
        }
    } else {
        next()        
    }
})
export default router;
