export const UserModule = {
    namespaced: true,
    state: {
        accessToken: "",
        roleId: null,
        isActiveToken: null,
        userInfo: {},
        teamList: [],
        totalRecord: 0,
        totalLenght: 0,
        tableLoader: 1,
    }
}