<template>
    <!-- <PriorityData></PriorityData>     -->
    <TeamMemberData></TeamMemberData>    
    <Projects></Projects>    
</template>
<script>
import Projects from '../components/Projects/index.vue'
// import PriorityData from '../components/Priority/PriorityData.vue'
import TeamMemberData from '../components/TeamMember/TeamMemberData.vue'
import $ from "jquery";
export default {
    name: 'ProjectsPage',
    components: {
        Projects,
        // PriorityData,
        TeamMemberData
    },
    async mounted() { 
        const bHeight = $('body').height();
        $('#content').addClass('firstHeightCls')
        $('.firstHeightCls').height(bHeight-23) 
        this.$store.state.pageTitle = 'Projects'
        this.$store.state.isLogin = 1    
        this.$store.state.NotifiCheckVal = Math.ceil(Math.random()*1000000)   
    },
}
</script>
