export const ProjectModule = {
    namespaced: true,
    state: {
        loadProjectData: 1,
        ProjectList: [],
        ProjectFilterList: [],
        projectList: [],
        totalRecord: 0,
        totalLenght: 0,
        tableLoader: 1,
    }
}